<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Gift") }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form method="post" class="row">
              <div class="col-md-2">
                <div class="mb-3">
                  <label for="sexType" class="form-label">
                    {{ $t("Type") }}
                  </label>

                  <b-form-select v-model="body.sexType" class="mb-3">
                    <b-form-select-option value="MR">{{
                      $t("MR")
                    }}</b-form-select-option>
                    <b-form-select-option value="MRS">{{
                      $t("MRS")
                    }}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

              <div class="col-md-5">
                <div class="mb-3">
                  <label for="fname" class="form-label">
                    {{ $t("First Name") }}</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="fname"
                    v-model="body.fname"
                  />
                </div>
              </div>
              <div class="col-md-5">
                <div class="mb-3">
                  <label for="lname" class="form-label">{{
                    $t("Last Name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lname"
                    v-model="body.lname"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="mb-3">
                  <label for="price" class="form-label">
                    {{ $t("Price Plan") }}
                    <!-- <span style="color:red">
                    {{ $t('per person') }}
                  </span> -->
                  </label>

                  <input
                    type="number"
                    class="form-control"
                    id="price"
                    v-model.number="body.price"
                  />
                </div>
              </div>

              <div class="col-md-8">
                <div class="mb-3">
                  <label for="address" class="form-label">
                    {{ $t("AddressHome") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    v-model="body.address"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="mb-3">
                  <label for="zip" class="form-label">
                    {{ $t("zip") }}
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="zip"
                    v-model.number="body.zip"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label for="city" class="form-label">
                    {{ $t("city") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    v-model="body.city"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="country" class="form-label">
                    {{ $t("country") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="country"
                    v-model="body.country"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="country" class="form-label">
                    {{ $t("Sender's Phone") }}
                  </label>
                  <vue-tel-input
                    v-model.trim="body.phone"
                    v-bind="{
                      mode: 'international',
                    }"
                    :allCountries="allCountries"
                  ></vue-tel-input>
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="country" class="form-label">
                    {{ $t("Reciever Phone") }}
                  </label>
                  <vue-tel-input
                    v-model.trim="body.recieverphone"
                    v-bind="{
                      mode: 'international',
                    }"
                    :allCountries="allCountries"
                  ></vue-tel-input>
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="validationTooltip04">{{
                    $t("Recieve Type")
                  }}</label>
                  <select
                    class="custom-select"
                    id="validationTooltip04"
                    v-model="body.recieverType"
                  >
                    <option value="email">{{ $t("email") }}</option>
                    <option value="himself">
                      {{ $t("Receive by himself") }}
                    </option>
                    <!-- <option value="post">{{ $t("post") }}</option> -->
                  </select>
                </div>
              </div>

              <div class="col-md-12" v-if="body.recieverType == 'email'">
                <div class="mb-3">
                  <label for="email" class="form-label">
                    {{ $t("Email") }}
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="body.email"
                  />
                </div>
              </div>


            

              <!-- <div class="col-md-12">
                <p>
                  {{ $t("Note:We can send") }}
                </p>
              </div> -->
            </form>
          </div>
        </div>
        <b-button
          block
          variant="danger"
          :disabled="
            body.fname == null || body.lname == null || body.price == 0
          "
          @click="add"
          style="background-color: #f26838; color: white"
          >{{ $t("Confirm") }}</b-button
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),

      body: {
        sexType: "MR",
        fname: null,
        lname: null,
        price: 0,
        address: null,
        zip: null,
        city: null,
        phone: null,
        country: null,
        recieverType: "himself",
        recieverphone: null,
      },
      userList: [],
      loading: false,
      byPost: 0,
    };
  },

  created() {
    this.$http.post(`users/getProfile`, {}).then((res) => {
      const user = res.data.data;

      this.body.usersId = user.id;
      this.body.email = user.email;
      this.body.phone = user.phone;
      this.body.sexType = user.sexType;
      this.body.fname = user.fname;
      this.body.lname = user.lname;
      this.body.address = user.address;
      this.body.zip = Number(user.zip);
      this.body.city = user.city;
      this.body.country = user.country;
    });
  },
  methods: {
    add() {
      if (
        this.body.fname &&
        this.body.lname &&
        this.body.price > 0 &&
        this.body.sexType
      ) {
        this.$eventHub.$emit("showSpinner", true);
        this.$http.post(`gifts`, this.body).then(
          (res) => {
            this.$eventHub.$emit("showSpinner", false);
            this.$swal.fire({
              icon: "success",
              title: this.$t("Success"),
              text: this.$t("toBay"),
              showConfirmButton: false,
              timer: 1500,
            });

            this.$router.push(
              "/PaymentGift/" +
                res.data.data.id +
                "/" +
                (Number(this.body.price) + Number(this.byPost))
            );
          },
          (err) => {
            this.$eventHub.$emit("showSpinner", false);
            this.$swal.fire({
              icon: "error",
              title: this.$t("Error"),
              text: this.$t("Something has gone wrong"),
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      } else {
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  watch: {
    "body.recieverType"(val) {
      if (val == "post") {
        this.byPost = 3;
      } else {
        this.byPost = 0;
      }
    },
  },
  head: {
    title: {
      inner: "Gift",
    },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>

<style></style>
